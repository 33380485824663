<template>
    <div
        v-if="isB2C"
        class="b2c__header"
    >
        <picture>
            <source
                srcset="/images/header-desktop.png"
                media="(min-width: 768px)"
            >
            <img
                src="/images/header_mobile.png"
                alt="B2C"
            >
        </picture>
        <h1
            class="b2c__title-main"
            v-html="$t('header_title')"
        ></h1>
    </div>
    <div
        v-else
        :class="type === 'mini' ? 'nps__question__mini-logo': 'text-left'"
    >
        <picture>
            <img
                :src="'../images/logo-' + divisionID + '.svg'"
                alt=""
                class="nps__logo"
            >
        </picture>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Logo',
    props: {
        type: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters('pollStore', ['divisionID', 'isB2C'])
    }
};
</script>

<style scoped>

</style>
