<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
                <div class="main__wrap">
                    <div
                        class="finish"
                        :class="{ 'b2c__finish': isB2C }"
                    >
                        <svg
                            v-if="isB2C"
                            width="94"
                            height="94"
                            viewBox="0 0 94 94"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M66.8345 32L38.1982 60.6364L25.1816 47.6198"
                                stroke="#44BD63"
                                stroke-width="3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <circle
                                cx="47"
                                cy="47"
                                r="45"
                                stroke="#44BD63"
                                stroke-width="3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Logo
                            v-if="!isB2C"
                            type="mini"
                            class="text-center"
                        />
                        <h1>{{ $t("thank_you") }}</h1>
                        <p>
                            {{ $t("increase_level") }}
                        </p>
                        <div class="timer">
                            <i18n
                                v-if="!isFramed"
                                path="redirect_timer"
                            >
                                <template v-slot:main_page>
                                    <a :href="redirectLink">{{ $t("main_page") }}</a>
                                </template>
                                <template v-slot:time>
                                    {{ time }}
                                </template>
                            </i18n>
                            <i18n
                                v-else
                                path="close_customer_page"
                            >
                                <template v-slot:time>
                                    {{ time }}
                                </template>
                            </i18n>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Logo from './Logo';
import { AituService } from '../services/aitu';
export default {
    name: 'FinishPage',
    components: {
        Logo
    },
    data() {
        return {
            time: 5
        };
    },

    computed: {
        ...mapGetters('pollStore', ['divisionID', 'getLanguage', 'isB2C']),

        redirectLink() {
            let url;
            switch (this.divisionID) {
            case 2:
                url = 'https://ismet.kz/' + this.getLanguage;
                break;
            default:
                url = 'https://telecom.kz/' + this.getLanguage;
                break;
            }
            return url;
        },
        isFramed() {
            return window !== window.top || document !== top.document || self.location !== top.location;
        }
    },

    created() {
        this.timer = setInterval(this.countdown, 1000);
        window.parent.postMessage('closeIframe', '*');
    },

    mounted() {
        AituService.disableBackArrow();
        this.sendFinishEvent();
    },

    methods: {
        ...mapActions('pollStore', ['sendEvent']),
        sendFinishEvent() {
            this.sendEvent({
                eventName: 'survey_finish'
            });
        },

        countdown() {
            if (this.time > 0) {
                this.time--;
                return;
            }
            if (!this.isFramed) {
                document.location.href = this.redirectLink;
            }

            clearInterval(this.timer);
        }
    }
};
</script>

<style scoped></style>
