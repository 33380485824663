const messages = {
    min: (field, [length]) => `Сообщение должно быть не менее ${length} символов.`,
    required: () => 'Поле обязательно для заполнения.',
    max: (field, [length]) => `Сообщение не может быть более ${length} символов.`

};

const locale = {
    name: 'ru',
    messages,
    attributes: {}
};

export default locale;
