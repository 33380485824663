const messages = {
    min: (field, [length]) => `Өрісінде кем дегенде ${length} таңба болуы керек`,
    required: () => 'Өрісті міндетті түрде толтыру керек.',
    max: (field, [length]) => `Өрісінде  ${length} артық таңба  болуы мүмкін емес.`
};

const locale = {
    name: 'kk',
    messages,
    attributes: {}
};

export default locale;
