<template>
    <div class="nps__head">
        <b-progress
            :value="index + 1"
            :max="length"
        >
            <b-progress-bar :value="index + 1">
                <span>
                    {{ ((100 / length) * (index + 1)).toFixed() }} %
                </span>
            </b-progress-bar>
        </b-progress>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        index: {
            type: Number,
            required: true
        },
        length: {
            type: Number,
            required: true
        }
    }
};
</script>

<style scoped>

</style>
