import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue } from 'bootstrap-vue';
import { i18n } from './plugins/i18n';
import './config/axios.config';
import '../src/sass/global.sass';
import VueCookie from 'vue-cookie';
import VeeValidate, { Validator } from 'vee-validate';
import attributesRu from '@/services/vee-validate/ru';
import attributesKk from '@/services/vee-validate/kk';
import { mapGetters } from 'vuex';
export const eventBus = new Vue();
Vue.config.productionTip = false;
Vue.use(VueCookie);
Vue.use(BootstrapVue);

Vue.use(VeeValidate, {
    locale: 'kk',
    classes: true,
    classNames: {
        valid: 'is-valid',
        invalid: 'is-invalid'
    }
});
Validator.localize('ru', attributesRu);
Validator.localize('kk', attributesKk);

// app init
// eslint-disable-next-line no-unused-vars
const app = new Vue({
    el: '#app',
    i18n,
    router,
    store,
    computed: {
        ...mapGetters({
            getLanguage: 'pollStore/getLanguage'
        })
    },
    beforeMount() {
        this.$validator.localize(this.getLanguage);
    },
    render: h => h(App)
});
