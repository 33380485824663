<template>
    <section>
        <h5
            class="nps__question__title"
            v-html="question.name"
        >
        </h5>
        <b-form-radio
            v-model="selectedInputRadio"
            v-validate="'required'"
            value="1"
            name="radio"
            class="d-block text-left ml-lg-5 mb-2"
        >
            {{ $t("yes") }}
        </b-form-radio>
        <b-form-radio
            v-model="selectedInputRadio"
            v-validate="'required'"
            value="2"
            name="radio"
            class="d-block text-left ml-lg-5"
        >
            {{ $t("no") }}
        </b-form-radio>
        <div
            v-show="errors.has('radio')"
            class="error"
        >
            {{ errors.first('radio') }}
        </div>

        <form-input-phone
            v-if="isChild && !phoneSent"
            v-model="phone"
            v-validate="'required|min:16'"
            name="phone"
            data-vv-name="phone"
        />
        <div
            v-show="errors.has('phone')"
            class="error"
        >
            {{ errors.first('phone') }}
        </div>
        <div class="d-flex justify-content-end">
            <button
                class="nps__btn nps__btn-next"
                @click.prevent="sendForm"
            >
                {{ $t("next") }}
            </button>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormInputPhone from './FormInputPhone';
export default {
    name: 'FormInputBoolean',
    components: { FormInputPhone },
    props: {
        question: {
            type: Object,
            required: true
        },
        isChild: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            selectedInputRadio: 1,
            phone: ''
        };
    },
    computed: {
        ...mapGetters('pollStore', ['phoneSent'])
    },
    methods: {
        ...mapActions('pollStore', ['sendEvent', 'nextQuestion', 'phoneSend']),

        sendForm() {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    if (this.phone) {
                        this.phoneSend();
                    }
                    this.sendEvent({
                        eventName: 'survey_answer',
                        questionId: this.question.id,
                        answers: [
                            {
                                id: 0,
                                value_str: null,
                                value_int: Number(this.selectedInputRadio)
                            }
                        ],
                        optional_answer: this.phone ? '; ' + this.phone : ''
                    });
                    this.nextQuestion();
                }
            });
        }
    }
};
</script>

<style scoped></style>
