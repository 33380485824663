<template>
    <div
        v-if="!isB2C"
        class="form__phone"
    >
        <p>{{ $t('bad_answer') }}</p>
        <input
            v-mask="['+7 ### ### ## ##']"
            name="phone"
            placeholder="+7 ___ ___ __ __"
            :value="value"
            @input="$emit('input', $event.target.value)"
        >
    </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapGetters } from 'vuex';
export default {
    name: 'FormInputPhone',
    directives: { mask },
    // eslint-disable-next-line vue/require-prop-types
    props: ['value'],
    computed: {
        ...mapGetters('pollStore', ['isB2C'])
    }
};
</script>

<style scoped>

</style>
