<template>
    <div>
        <h5
            class="nps__question__title"
            v-html="question.name"
        >
        </h5>
        <b-form-radio
            v-for="(item, index) in filteredQuestion"
            :key="index"
            v-model="selectedInputRadio"
            v-validate="'required'"
            :value="item"
            name="radio"
            class="d-block text-left ml-lg-5 mb-2"
        >
            {{ item.value }}
        </b-form-radio>

        <b-form-textarea
            v-if="showMessageField"
            v-model="anotherMessageField"
            v-validate="showMessageField ? 'required|min:10|max:1000' : ''"
            class="mx-lg-5 mt-3"
            :class="{'b2c__textarea' : isB2C }"
            name="message"
            :disabled="isCheckAnswered"
        />
        <div
            v-show="errors.has('message')"
            class="error"
        >
            {{ errors.first('message') }}
        </div>
        <div
            v-show="errors.has('radio')"
            class="error"
        >
            {{ errors.first('radio') }}
        </div>
        <form-input-phone
            v-if="isChild && !phoneSent"
            v-model="phone"
            v-validate="'required|min:16'"
            name="phone"
            data-vv-name="phone"
        />
        <div
            v-show="errors.has('phone')"
            class="error"
        >
            {{ errors.first('phone') }}
        </div>
        <div
            class="d-flex"
            :class="isB2C ? 'justify-content-center' : 'justify-content-end'"
        >
            <button
                class="nps__btn nps__btn-next"
                :class="{'b2c__next' : isB2C }"
                @click.prevent="sendForm"
            >
                {{ $t("next") }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import FormInputPhone from './FormInputPhone';

export default {
    name: 'FormInputRadio',
    components: { FormInputPhone },
    props: {
        question: {
            type: Object,
            required: true
        },
        isChild: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            selectedInputRadio: null,
            showMessageField: false,
            anotherMessageField: '',
            isCheckAnswered: false,
            phone: ''
        };
    },
    computed: {
        ...mapGetters('pollStore', ['languageId', 'divisionID', 'isB2C', 'phoneSent']),
        filteredQuestion() {
            const answerValues = _.map(this.question.answerValues, each => {
                return _.find(each.answerValueLanguages, { languageId: this.languageId });
            });
            return _.sortBy(answerValues, ['id']);
        },
        checkSelectedRadio() {
            return this.selectedInputRadio.value === this.$t('another');
        }
    },

    watch: {
        selectedInputRadio() {
            this.isSelectedAnother();
        }
    },

    methods: {
        ...mapActions('pollStore', ['sendEvent', 'nextQuestion', 'phoneSend']),
        isSelectedAnother() {
            const result = this.checkSelectedRadio;
            this.showMessageField = !!result;
        },
        sendForm() {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    if (this.phone) {
                        this.phoneSend();
                    }
                    this.sendEvent({
                        eventName: 'survey_answer',
                        questionId: this.question.id,
                        answers: [
                            {
                                id: this.selectedInputRadio.id,
                                value_str: this.selectedInputRadio.value,
                                value_int: null,
                                optional_answer: this.checkSelectedRadio ? this.anotherMessageField + '; ' + this.phone : '; ' + this.phone
                            }
                        ],
                        optional_answer: ''
                    });
                    this.nextQuestion();
                }
            });
        }
    }
};
</script>

<style scoped></style>
