<template>
    <div>
        <template v-if="!showChildComponent">
            <h5
                class="nps__question__title"
                v-html="question.name"
            >
            </h5>

            <div
                class="rating-area"
                :class="{'rating-area rating-area-b2b' : !isB2C, 'is-five' : endValue === 5 }"
            >
                <span
                    v-for="(rating, index) in endValue"
                    :key="index"
                >
                    <input
                        :id="'circle-' + rating"
                        v-model="answerValuesValue"
                        type="radio"
                        name="rating"
                        :value="rating"
                        :class="{'checked' : rating === answerValuesValue, 'is-five' : endValue === 5 }"
                    >
                    <label
                        :for="'circle-' + rating"
                    >
                        <span>
                            {{ rating }}
                        </span>
                    </label>
                </span>
            </div>
            <div
                v-if="!isB2C"
                class="notice"
                :class="{'is-five' : endValue === 5 }"
            >
                <p class="notice__bad">
                    {{ endValue === 5 ? $t("bad") : $t("no") }}
                </p>
                <p class="notice__normal">
                    {{ endValue === 5 ? $t("good") : $t("maybe") }}
                </p>
                <p class="notice__good">
                    {{ endValue === 5 ? $t("great") : $t("yes") }}
                </p>
            </div>
            <p>{{ $t("your_answer") }} {{ answerValuesValue }}</p>

            <div
                v-show="errors.has('range')"
                class="error"
            >
                {{ errors.first('range') }}
            </div>
            <div
                class="d-flex"
                :class="isB2C ? 'justify-content-center' : 'justify-content-end'"
            >
                <button
                    class="nps__btn nps__btn-next"
                    :class="{'b2c__next' : isB2C }"
                    :disabled='!answerValuesValue'
                    @click.prevent="sendForm"
                >
                    {{ $t("next") }}
                </button>
            </div>
        </template>
        <template v-if="question.children.length && showChildComponent">
            <div
                v-for="(item, index) in question.children"
                :key="index"
                class="nps__question"
            >
                <component
                    :is="item.valueInputTypeName"
                    :question="item"
                    is-child
                />
            </div>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormInputRange from '@/components/form/FormInputRange';
import FormInputCheckbox from '@/components/form/FormInputCheckbox';
import FormInputRadio from '@/components/form/FormInputRadio';
import FormInputMessage from '@/components/form/FormInputMessage';
import FormInputBoolean from '@/components/form/FormInputBoolean';

export default {
    name: 'FormInputRange',
    components: {
        CHECKBOX: FormInputCheckbox,
        RANGE: FormInputRange,
        RADIO: FormInputRadio,
        MESSAGE: FormInputMessage,
        BOOLEAN: FormInputBoolean
    },
    props: {
        question: {
            type: Object,
            required: true
        },
        isChild: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            answerValuesValue: 0,
            isCheckAnswered: false,
            showChildComponent: false
        };
    },

    computed: {
        ...mapGetters('pollStore', ['divisionID', 'step', 'isB2C']),

        startValue() {
            return this.question.answerValues[0].valueFrom;
        },

        endValue() {
            return this.question.answerValues[0].valueTo;
        }
    },

    methods: {
        ...mapActions('pollStore', ['sendEvent', 'nextQuestion']),
        sendForm() {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    this.sendEvent({
                        eventName: 'survey_answer',
                        questionId: this.question.id,
                        answers: [
                            {
                                id: this.question.id,
                                value_str: null,
                                value_int: Number(this.answerValuesValue)
                            }
                        ]
                    });
                }
                if ((this.answerValuesValue <= this.question.valueForChildAvailable) && this.question.children.length) {
                    this.showChildComponent = true;
                } else {
                    this.nextQuestion();
                }
            });
        }
    }
};
</script>

<style scoped>
</style>
