<template>
    <div>
        <div :class="isB2C ? 'b2c__wrap' : 'nps__wrap'">
            <div
                v-if="isStartPage"
                :class="isB2C ? 'b2c__inner' : 'nps__inner'"
            >
                <Logo />
                <template v-if="isB2C">
                    <h3 class="nps__title b2c__title">
                        {{ $t("b2c__main_title") }}
                    </h3>
                    <div class="b2c__buttons">
                        <button
                            class="nps__btn b2c__btn"
                            :class="languageSelected === 'kk' ? 'selected' : ''"
                            @click="selectLanguage('kk')"
                        >
                            қаз
                        </button>
                        <button
                            class="nps__btn b2c__btn"
                            :class="languageSelected === 'ru' ? 'selected' : ''"
                            @click="selectLanguage('ru')"
                        >
                            рус
                        </button>
                    </div>
                    <div class="divider"></div>
                    <button
                        class="nps__btn b2c__next"
                        @click="passSurvey"
                    >
                        {{ $t("to_answer") }}
                    </button>
                </template>
                <template v-else>
                    <h3 class="nps__title">
                        {{ $t("main_title") }}
                    </h3>
                    <p
                        class="nps__description text-left"
                        v-html="$t('main_description')"
                    ></p>
                    <div class="nps__select__wrapper">
                        <b-form-select
                            v-model="languageSelected"
                            :options="languageOptions"
                            label-field="Выберите язык"
                        />
                        <button
                            class="nps__btn mt-5"
                            @click="passSurvey"
                        >
                            {{ $t("to_answer") }}
                        </button>
                    </div>
                </template>
            </div>
            <div
                v-if="!isStartPage"
                :class="isB2C ? 'b2c__questions' : 'nps__questions'"
            >
                <div
                    v-for="(item, index) in sortedQuestions"
                    :key="index"
                    class=""
                    :class="isB2C ? 'b2c__question' : 'nps__question'"
                >
                    <template v-if="step === index">
                        <Logo type="mini" />
                        <ProgressBar
                            :index="index"
                            :length="sortedQuestions.length"
                        />
                        <FormMain :question="item" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormMain from '@/components/form/FormMain';
import { mapActions, mapGetters } from 'vuex';
import Logo from './Logo';
import ProgressBar from './ProgressBar';

export default {
    name: 'Recommendations',
    components: { Logo, ProgressBar, FormMain },

    props: {
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            languageSelected: 'kk',
            languageOptions: [
                { value: 'ru', text: 'Русский' },
                { value: 'kk', text: 'Қазақша' }
            ]
        };
    },
    metaInfo() {
        return {
            title: 'Анкета Казактелеком 2021'
        };
    },
    computed: {
        ...mapGetters('pollStore', ['divisionID', 'step', 'getLanguage', 'languageId', 'isB2C']),
        sortedQuestions() {
            const array = this.data;
            const newArray = [];

            array.sort((prev, next) => {
                return prev.displayOrder - next.displayOrder;
            });
            array.forEach((item) => {
                const newItem = this.filteringObject(item);
                const childrenArray = [];
                item.children.forEach(i => {
                    const oneChildren = this.filteringObject(i);
                    childrenArray.push(oneChildren);
                });
                newArray.push(
                    { ...newItem, children: childrenArray });
            });
            return newArray;
        },

        isStartPage() {
            return !(this.step >= 0);
        }
    },
    watch: {
        languageSelected() {
            this.setLanguage();
            this.setLanguageId();
        }
    },
    mounted() {
        this.setLanguage();
    },

    beforeDestroy() {
        this.sendCloseEvent();
    },

    methods: {
        ...mapActions('pollStore', ['sendEvent', 'nextQuestion', 'updateLanguageId']),
        filterByLanguage(item, lang) {
            return item.filter((el) => {
                return el.languageId === lang;
            });
        },
        filteringObject(item) {
            const filteredItem = this.filterByLanguage(item.questionLanguages, this.languageId);
            return {
                displayOrder: item.displayOrder,
                answerValues: item.answerValues,
                name: filteredItem[0].name,
                languageId: this.languageId,
                id: item.id,
                valueInputTypeName: item.valueInputTypeName,
                valueForChildAvailable: item.valueForChildAvailable
            };
        },
        passSurvey() {
            this.nextStep();
            this.sendOpenEvent();
        },

        setLanguage() {
            import('../plugins/langs/' + this.languageSelected + '.json').then(content => {
                this.$i18n.setLocaleMessage(this.languageSelected, content);
                this.$i18n.locale = this.languageSelected;
                this.$validator.localize(this.languageSelected);
            });
        },
        selectLanguage(lang) {
            this.languageSelected = lang;
            this.setLanguage();
        },
        setLanguageId() {
            this.updateLanguageId(this.languageSelected);
        },
        nextStep() {
            this.nextQuestion();
        },

        sendOpenEvent() {
            this.sendEvent({
                eventName: 'survey_open'
            });
        },

        sendCloseEvent() {
            this.sendEvent({
                eventName: 'survey_close'
            });
        }
    }
};
</script>
