<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
                <div class="main__wrap">
                    <div class="finish">
                        <p
                            class="text-center"
                            v-html="$t('doesnt_active_link')"
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotActiveLink'
};
</script>

<style scoped></style>
