import axios from '../../config/axios.config';
import mutations from '../mutatios';
import router from '@/router/index';
import TypeName from '../../services/type-name';
import VueCookie from 'vue-cookie';
import MD5 from 'crypto-js/md5';
const {
    SURVEYTEMPLATES,
    SET_CUSTOMER_ACCOUNT_ID,
    SET_DIVISION_ID,
    SET_SURVEY_TEMPLATE_ID,
    SET_LANGUAGE_ID,
    DECREMENT_STEP,
    INCREMENT_STEP,
    PHONE_SEND
} = mutations;

const vueAppToken = process.env.VUE_APP_TOKEN;
const pollStore = {
    namespaced: true,
    state: {
        customerAccountId: '',
        division: '',
        surveyTemplates: [],
        surveyTemplateId: null,
        languageId: 2,
        language: 'kk',
        step: -1,
        phoneSent: false
    },
    getters: {
        surveyTemplates: ({ surveyTemplates }) => surveyTemplates,
        customerAccountId: ({ customerAccountId }) => customerAccountId,
        divisionID: ({ division }) => division,
        surveyTemplateId: ({ surveyTemplateId }) => surveyTemplateId,
        getLanguage: ({ language }) => language,
        languageId: ({ languageId }) => languageId,
        step: ({ step }) => step,
        isB2C: ({ division }) => division === 3,
        phoneSent: ({ phoneSent }) => phoneSent
    },
    mutations: {
        [SURVEYTEMPLATES](state, value) {
            const arr = value.surveyTemplates[0].questions.map(item => {
                const name = new TypeName();
                const valueInputTypeName = name.path(item.valueInputTypeId, item.valueTypeId);
                return { ...item, valueInputTypeName };
            });
            state.surveyTemplates = arr.filter(item => {
                item.children = arr.filter(i => {
                    if (item.parentQuestionId === i.id) {
                        return false;
                    } else {
                        return i.parentQuestionId === item.id;
                    }
                });
                return item.parentQuestionId === item.id;
            });
        },
        [SET_CUSTOMER_ACCOUNT_ID](state, value) {
            state.customerAccountId = value;
        },
        [SET_DIVISION_ID](state, value) {
            state.division = value;
        },
        [SET_SURVEY_TEMPLATE_ID](state, value) {
            state.surveyTemplateId = value;
        },
        [SET_LANGUAGE_ID](state, lang) {
            switch (lang) {
            case 'kk':
                state.languageId = 2;
                break;
            default:
                state.languageId = 1;
            }
            state.language = lang;
        },
        [INCREMENT_STEP](state) {
            state.step++;
        },
        [DECREMENT_STEP](state) {
            state.step--;
        },
        [PHONE_SEND](state) {
            state.phoneSent = true;
        }
    },
    actions: {
        initStore({ commit }, { hash, customerAccountId, division }) {
            axios
                .get(
                    `nps?hash=${hash}&customerAccountId=${customerAccountId}&division=${division}`, {
                        headers: {
                            tokensurvey: MD5(customerAccountId + vueAppToken).toString()
                        }
                    }
                )
                .then(res => {
                    commit(SURVEYTEMPLATES, res.data);
                    commit(SET_CUSTOMER_ACCOUNT_ID, res.data.customerAccount);
                    commit(SET_DIVISION_ID, res.data.division);
                    commit(SET_SURVEY_TEMPLATE_ID, res.data.surveyTemplates[0].id);
                })
                .catch(() => {
                    router.push({
                        name: 'NotActiveLink'
                    });
                });
        },
        nextQuestion({ commit, state }) {
            if (state.step === state.surveyTemplates.length - 1) {
                router.push({
                    name: 'finishPage'
                });
            }
            commit(INCREMENT_STEP);
        },
        updateLanguageId({ commit }, lang) {
            commit(SET_LANGUAGE_ID, lang);
        },
        phoneSend({ commit }) {
            commit(PHONE_SEND);
        },
        // eslint-disable-next-line camelcase
        sendEvent({ getters, commit }, { eventName, questionId, answers = [], optional_answer = null }) {
            axios
                .post('/event', {
                    customerAccountId: getters.customerAccountId,
                    surveyTemplateId: getters.surveyTemplateId,
                    languageId: getters.languageId,
                    operationName: eventName, // "survey_open", "survey_answer", "survey_finish", "survey_close"
                    questionId,
                    answers,
                    optional_answer,
                    sessionId: VueCookie.get('sessionId')
                },
                {
                    headers: {
                        tokensurvey: MD5(getters.customerAccountId + vueAppToken).toString()
                    }
                });
        }
    }
};

export default pollStore;
