import aituBridge from '@btsd/aitu-bridge';

export class AituService {
    static isSupported(method) {
        if (method) {
            return aituBridge.supports(method);
        }

        return aituBridge.isSupported();
    }

    static async enableBackArrow(state = true) {
        if (!AituService.isSupported()) {
            return;
        }

        try {
            await aituBridge.setCustomBackArrowMode(state);
        } catch (error) {
            console.error('Error with `setCustomBackArrowMode` method', error);
        }
    }

    static async showBackArrow(state = true) {
        if (!AituService.isSupported()) {
            return;
        }

        try {
            await aituBridge.setCustomBackArrowVisible(state);
        } catch (error) {
            console.error(
                'Error with `setCustomBackArrowVisible` method',
                error
            );
        }
    }

    static setBackArrowHandler(handler) {
        if (!AituService.isSupported()) {
            return;
        }

        try {
            aituBridge.setCustomBackArrowOnClickHandler(handler);
        } catch (error) {
            console.error(
                'Error with `setCustomBackArrowOnClickHandler` method',
                error
            );
        }
    }

    static disableBackArrow() {
        if (!AituService.isSupported()) {
            return;
        }

        AituService.enableBackArrow();
        AituService.showBackArrow(false);
    }

    static closeApplication() {
        if (!AituService.isSupported()) {
            return;
        }

        return aituBridge.closeApplication();
    }
}
