<template>
    <section>
        <h5
            class="nps__question__title"
            v-html="question.name"
        >
        </h5>
        <b-form-checkbox-group
            :id="question.answerValues.id"
            v-model="answer"
            v-validate="'required'"
            :name="question.answerValues.id"
            data-vv-name="checkbox"
        >
            <b-form-checkbox
                v-for="(item, index) in filteredQuestion"
                :id="'checkbox-' + item.id"
                :key="index"
                :value="item"
                class="d-block text-left ml-lg-5 mb-2"
            >
                {{ item.value }}
            </b-form-checkbox>
        </b-form-checkbox-group>
        <b-form-textarea
            v-if="showMessageField"
            v-model="messageField"
            v-validate="showMessageField ? 'required|min:10|max:1000' : ''"
            class="mx-lg-5 mt-3"
            :class="{'b2c__textarea' : isB2C }"
            name="message"
        />
        <div
            v-if="errors.has('message')"
            class="error"
        >
            {{ errors.first('message') }}
        </div>
        <div
            v-if="errors.has('checkbox')"
            class="error"
        >
            {{ errors.first('checkbox') }}
        </div>
        <form-input-phone
            v-if="isChild && !phoneSent"
            v-model="phone"
            v-validate="'required|min:16'"
            name="phone"
            data-vv-name="phone"
        />
        <div
            v-show="errors.has('phone')"
            class="error"
        >
            {{ errors.first('phone') }}
        </div>
        <div class="d-flex justify-content-end">
            <button
                class="nps__btn nps__btn-next"
                type="submit"
                @click.prevent="sendForm"
            >
                {{ $t("next") }}
            </button>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import FormInputPhone from './FormInputPhone';
export default {
    name: 'FormInputCheckbox',
    components: { FormInputPhone },
    props: {
        question: {
            type: Object,
            required: true
        },
        isChild: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            answer: [],
            messageField: null,
            showMessageField: false,
            phone: ''
        };
    },
    computed: {
        ...mapGetters('pollStore', ['languageId', 'divisionID', 'isB2C', 'phoneSent']),
        filteredQuestion() {
            const answerValues = _.map(this.question.answerValues, each => {
                return _.find(each.answerValueLanguages, { languageId: this.languageId });
            });
            console.log(_.sortBy(answerValues, ['id']));
            return _.sortBy(answerValues, ['id']);
        },
        checkSelectedCheckbox() {
            return this.answer.find(
                item => item.value === this.$t('another')
            );
        }
    },
    watch: {
        answer() {
            this.isSelectedAnother();
        }
    },

    methods: {
        ...mapActions('pollStore', ['sendEvent', 'nextQuestion', 'phoneSend']),

        isSelectedAnother() {
            const result = this.checkSelectedCheckbox;
            this.showMessageField = !!result;
        },

        sendForm() {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    this.messageField = this.checkSelectedCheckbox ? this.messageField : null;
                    if (this.phone) {
                        this.phoneSend();
                    }
                    const mappedAnswers = this.answer.map(item => {
                        return { id: item.id, value_str: item.value, value_int: null };
                    });

                    this.sendEvent({
                        eventName: 'survey_answer',
                        questionId: this.question.id,
                        answers: mappedAnswers,
                        optional_answer: this.checkSelectedCheckbox ? this.messageField + '; ' + this.phone : '; ' + this.phone
                    });

                    this.nextQuestion();
                }
            });
        }
    }
};
</script>

<style scoped></style>
