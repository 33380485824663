<template>
    <section>
        <h5
            class="nps__question__title"
            v-html="question.name"
        >
        </h5>
        <b-form-textarea
            id="textarea"
            v-model="messageText"
            v-validate="'required|min:10|max:1000'"
            :placeholder="$t('entry_message')"
            name="message"
            rows="5"
        />
        <div
            v-show="errors.has('message')"
            class="error"
        >
            {{ errors.first('message') }}
        </div>
        <form-input-phone
            v-if="isChild && !phoneSent"
            v-model="phone"
            v-validate="'required|min:16'"
            name="phone"
            data-vv-name="phone"
        />
        <div
            v-show="errors.has('phone')"
            class="error"
        >
            {{ errors.first('phone') }}
        </div>
        <div class="d-flex justify-content-end">
            <button
                class="nps__btn nps__btn-next"
                @click.prevent="sendForm"
            >
                {{ $t("next") }}
            </button>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormInputPhone from './FormInputPhone';
export default {
    name: 'FormInputMessage',
    components: { FormInputPhone },
    props: {
        question: {
            type: Object,
            required: true
        },
        isChild: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            messageText: '',
            phone: ''
        };
    },
    computed: {
        ...mapGetters('pollStore', ['phoneSent'])
    },
    methods: {
        ...mapActions('pollStore', ['sendEvent', 'nextQuestion', 'phoneSend']),

        sendForm() {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    if (this.phone) {
                        this.phoneSend();
                    }
                    this.sendEvent({
                        eventName: 'survey_answer',
                        questionId: this.question.id,
                        answers: null,
                        optional_answer: this.messageText + (this.phone ? '; ' + this.phone : '')
                    });
                    this.nextQuestion();
                }
            });
        }
    }
};
</script>

<style scoped></style>
