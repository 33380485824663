import axios from 'axios';

const baseURL = process.env.VUE_APP_ROOT_API;
const instance = axios.create({
    baseURL: baseURL,
    headers: {
        Accepts: 'application/json',
        'Content-Type': 'application/json;charset=utf-8'
    }
});

export default instance;
