<template>
    <form>
        <div class="nps__question__info">
            <component
                :is="question.valueInputTypeName"
                :question="question"
            />
        </div>
    </form>
</template>

<script>
import FormInputRange from '@/components/form/FormInputRange';
import FormInputCheckbox from '@/components/form/FormInputCheckbox';
import FormInputRadio from '@/components/form/FormInputRadio';
import FormInputMessage from '@/components/form/FormInputMessage';
import FormInputBoolean from '@/components/form/FormInputBoolean';

export default {
    name: 'FormMain',
    components: {
        CHECKBOX: FormInputCheckbox,
        RANGE: FormInputRange,
        RADIO: FormInputRadio,
        MESSAGE: FormInputMessage,
        BOOLEAN: FormInputBoolean
    },
    props: {
        question: {
            type: Object,
            default: () => {}
        }
    }
};
</script>

<style scoped></style>
