const mutations = {
    SURVEYTEMPLATES: 'SURVEYTEMPLATES',
    SET_CUSTOMER_ACCOUNT_ID: 'SET_CUSTOMER_ACCOUNT_ID',
    SET_DIVISION_ID: 'SET_DIVISION_ID',
    SET_SURVEY_TEMPLATE_ID: 'SET_SURVEY_TEMPLATE_ID',
    SET_LANGUAGE_ID: 'SET_LANGUAGE_ID',
    INCREMENT_STEP: 'INCREMENT_STEP',
    DECREMENT_STEP: 'DECREMENT_STEP',
    PHONE_SEND: 'PHONE_SEND'
};

export default mutations;
