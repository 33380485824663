<template>
    <div :class="{'b2c__bg': isB2C}">
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <div
                        class="main__wrap"
                        :class="{'d-block mt-5': isB2C}"
                    >
                        <b-overlay
                            :show="processing"
                            rounded="sm"
                            class="w-100"
                        >
                            <recommendations
                                v-if="showRecommendations"
                                :data="data"
                            />

                            <div
                                v-if="showErrorStatus"
                                class="text-center"
                            >
                                "Уважаемый пользователь, <br>данная ссылка не активна"
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Recommendations from '@/components/Recommendations';

export default {
    name: 'Home',
    components: {
        Recommendations
    },
    data() {
        return {
            processing: true,
            showRecommendations: false,
            showErrorStatus: false,
            data: []
        };
    },

    computed: {
        ...mapGetters('pollStore', ['surveyTemplates', 'isB2C'])
    },

    watch: {
        surveyTemplates() {
            this.getSurvey();
        }
    },

    created() {
        this.init();
        this.setSessionId();
    },

    methods: {
        ...mapActions('pollStore', ['initStore']),

        init() {
            this.initStore({
                hash: this.$route.params.id,
                customerAccountId: this.$route.query.ca,
                division: this.$route.query.di || this.$route.query['amp;di']
            });
        },

        getSurvey() {
            this.data = this.surveyTemplates;
            if (this.data.surveyTemplates !== null) {
                this.showRecommendations = true;
            } else {
                this.showErrorStatus = true;
            }
            this.processing = false;
        },

        setSessionId() {
            this.$cookie.set('sessionId', this.generateSessionId(), { expires: '1h' });
        },

        generateSessionId() {
            const sessionId = this.$cookie.get('sessionId');
            if (sessionId) {
                return sessionId;
            }
            let randomString = '';
            while (randomString.length < 16) {
                randomString += Math.random().toString(36).substring(2);
            }
            return randomString.substring(0, 16);
        }
    }
};
</script>
