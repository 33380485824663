import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import FinishPage from '../components/FinishPage.vue';
import NotActiveLink from '../components/NotActiveLink.vue';
import Meta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(Meta);
const routes = [
    {
        path: '/',
        name: 'NotActiveLink',
        component: NotActiveLink
    },
    {
        path: '/:id',
        name: 'Home',
        component: Home
    },
    {
        path: '/finish',
        name: 'finishPage',
        component: FinishPage
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotActiveLink
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
