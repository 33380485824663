export default class TypeName {
    constructor() {
        this.valueInputTypeName = '';
    }

    path(valueInputTypeId, valueTypeId) {
        switch (valueInputTypeId) {
        case 1:
            if (valueTypeId === 2) {
                this.valueInputTypeName = 'RADIO';
            }
            if (valueTypeId === 3) {
                this.valueInputTypeName = 'RADIO';
            }
            if (valueTypeId === 4) {
                this.valueInputTypeName = 'BOOLEAN';
            }
            break;
        case 2:
            this.valueInputTypeName = 'RANGE';
            break;
        case 3:
        case 4:
            if (valueTypeId === 1) {
                this.valueInputTypeName = 'MESSAGE';
            }
            if (valueTypeId === 2) {
                this.valueInputTypeName = 'MESSAGE';
            }
            if (valueTypeId === 3) {
                this.valueInputTypeName = 'RADIO';
            }
            if (valueTypeId === 4) {
                this.valueInputTypeName = 'CHECKBOX';
            }
            break;
        }
        return this.valueInputTypeName;
    }
}
